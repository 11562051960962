import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Authors from "./pages/pages/Blogs/Authors";
import AddAuthor from "./pages/pages/Blogs/AddAuthor";
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import Professionals from "./pages/pages/Professionals/Professionals";
import AddProfession from "./pages/pages/Professionals/AddProfession";
import ProductFaq from "./pages/pages/Products/Faq/Faq";
import AddProductFaq from "./pages/pages/Products/Faq/AddFaq";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

//blogs
const Blog = async(() => import("./pages/pages/Blogs/Blogs"));
const AddBlog = async(() => import("./pages/pages/Blogs/AddBlog"));

//blogs posts
const BlogPosts = async(() => import("./pages/pages/Blogs/BlogPosts"));
const AddBlogPost = async(() => import("./pages/pages/Blogs/AddBlogPost"));

//blog categories
const BlogCategories = async(() => import("./pages/pages/Blogs/Categories"));
const AddCategory = async(() => import("./pages/pages/Blogs/AddCategory"));

//industries
const Industries = async(() => import("./pages/pages/Industries/Industries"));
const AddIndustry = async(() => import("./pages/pages/Industries/AddIndustry"));

//products
const Products = async(() => import("./pages/pages/Products/Products"));
const AddProduct = async(() => import("./pages/pages/Products/AddProduct"));
const ProductMapping = async(() =>
  import("./pages/pages/Products/ProductMapping")
);

//roles
const Roles = async(() => import("./pages/pages/Roles/Roles"));
const AddRole = async(() => import("./pages/pages/Roles/AddRole"));

//agents
const Agents = async(() => import("./pages/pages/Agents/Agents"));
const ProspectAgents = async(() =>
  import("./pages/pages/Agents/ProspectAgents")
);

//admins
const Admins = async(() => import("./pages/pages/Admins/Admins"));
const AddAdmin = async(() => import("./pages/pages/Admins/AddAdmin"));

//cms
const Sections = async(() => import("./pages/pages/CMS/Sections"));
const AddSection = async(() => import("./pages/pages/CMS/AddSection"));
const Pages = async(() => import("./pages/pages/CMS/Pages"));
const AddPage = async(() => import("./pages/pages/CMS/Add"));
const EditPage = async(() => import("./pages/pages/CMS/EditPage"));

//profile
const Profile = async(() => import("./pages/pages/Profile/Profile"));
const UpdateProfile = async(() =>
  import("./pages/pages/Profile/UpdateProfile")
);
const ChangePassword = async(() =>
  import("./pages/pages/Profile/ChangePassword")
);

//notifications
const Notifications = async(() =>
  import("./pages/pages/notifications/Notifications")
);

//testimonials
const Testimonial = async(() =>
  import("./pages/pages/Testimonial/Testimonial")
);
const AddTestimonial = async(() =>
  import("./pages/pages/Testimonial/AddTestimonial")
);

//news-letter
const NewsLetter = async(() => import("./pages/pages/News-Letter/NewsLetter"));

//faq
const Faq = async(() => import("./pages/pages/Faq/Faq"));
const AddFaq = async(() => import("./pages/pages/Faq/AddFaq"));

//partners
const Partners = async(() => import("./pages/pages/Partners/Partners"));
const PartnerImages = async(() =>
  import("./pages/pages/Partners/PartnerImages")
);
const AddPartnerImage = async(() =>
  import("./pages/pages/Partners/AddPartnerImage")
);

//contacts
const Contacts = async(() => import("./pages/pages/Contacts/Contacts"));

//carriers
const Carriers = async(() => import("./pages/pages/Carriers/Carriers"));
const AddCarrier = async(() => import("./pages/pages/Carriers/AddCarrier"));

//claims
const Claims = async(() => import("./pages/pages/Claims/Claims"));

//opportunities
const Opportunities = async(() =>
  import("./pages/pages/Opportunities/Opportunities")
);

//seo
const Seo = async(() => import("./pages/pages/Seo/Seo"));
const AddEditSeoMeta = async(() => import("./pages/pages/Seo/AddEditSeoMeta"));

//document pages
const DocumentPage = async(() => import("./pages/pages/Docs/DocumentPage"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));

const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  // {
  //   path: "/",
  //   element: <PresentationLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
    ],
  },
  {
    path: "/blogs",
    element: <DashboardLayout />,
    children: [
      {
        path: "authors",
        element: <Authors />,
      },
      {
        path: ":blogId/authors/add",
        element: <AddAuthor />,
      },
      {
        path: ":blogId/authors/edit/:id",
        element: <AddAuthor />,
      },
      {
        path: "categories",
        element: <BlogCategories />,
      },
      {
        path: ":blogId/categories/add",
        element: <AddCategory />,
      },
      {
        path: ":blogId/categories/edit/:id",
        element: <AddCategory />,
      },
      {
        path: "list",
        element: <Blog />,
      },
      {
        path: "list/add",
        element: <AddBlog />,
      },
      {
        path: "list/edit/:id",
        element: <AddBlog />,
      },
      {
        path: "posts",
        element: <BlogPosts />,
      },
      {
        path: ":blogId/posts/add",
        element: <AddBlogPost />,
      },
      {
        path: ":blogId/posts/edit/:id",
        element: <AddBlogPost />,
      },
    ],
  },
  {
    path: "/cms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pages/:id/sections",
        element: <Sections />,
      },
      {
        path: "pages/:id/sections/add",
        element: <AddSection />,
      },
      {
        path: "pages/:id/sections/edit/:tag",
        element: <AddSection />,
      },
      {
        path: "pages",
        element: <Pages />,
      },
      {
        path: "pages/add",
        element: <AddPage />,
      },
      {
        path: "pages/edit/:id",
        element: <EditPage />,
      },
    ],
  },
  {
    path: "/industry",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Industries />,
      },
      {
        path: "add",
        element: <AddIndustry />,
      },
      {
        path: "edit/:id",
        element: <AddIndustry />,
      },
    ],
  },
  {
    path: "/profession",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Professionals />,
      },
      {
        path: "add",
        element: <AddProfession />,
      },
      {
        path: "edit/:id",
        element: <AddProfession />,
      },
    ],
  },
  {
    path: "/products",
    element: <DashboardLayout />,
    children: [
      {
        path: "all",
        element: <Products />,
      },
      {
        path: "add",
        element: <AddProduct />,
      },
      {
        path: "edit/:id",
        element: <AddProduct />,
      },
      {
        path: "faq",
        element: <ProductFaq />,
      },
      {
        path: "faq/:productId/add",
        element: <AddProductFaq />,
      },
      {
        path: "faq/:productId/edit/:id",
        element: <AddProductFaq />,
      },
      {
        path: "mapping",
        element: <ProductMapping />,
      },
    ],
  },
  {
    path: "/roles",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Roles />,
      },
      {
        path: "add",
        element: <AddRole />,
      },
      {
        path: "edit/:id",
        element: <AddRole />,
      },
    ],
  },
  {
    path: "/agents",
    element: <DashboardLayout />,
    children: [
      {
        path: "list",
        element: <Agents />,
      },
      {
        path: "prospect-agents",
        element: <ProspectAgents />,
      },
    ],
  },
  {
    path: "/admins",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Admins />,
      },
      {
        path: "add",
        element: <AddAdmin />,
      },
    ],
  },
  {
    path: "/profile",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Profile />,
      },
      {
        path: "update",
        element: <UpdateProfile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/notifications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "/testimonials",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Testimonial />,
      },
      {
        path: "add",
        element: <AddTestimonial />,
      },
      {
        path: "edit/:id",
        element: <AddTestimonial />,
      },
    ],
  },
  {
    path: "/news-letter",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <NewsLetter />,
      },
    ],
  },
  {
    path: "/faq",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Faq />,
      },
      {
        path: ":industryId/add",
        element: <AddFaq />,
      },
      {
        path: ":industryId/edit/:id",
        element: <AddFaq />,
      },
    ],
  },
  {
    path: "/partners",
    element: <DashboardLayout />,
    children: [
      {
        path: "/partners/prospect-partners",
        element: <Partners />,
      },
      {
        path: "/partners/images-list",
        element: <PartnerImages />,
      },
      {
        path: "/partners/images-list/add",
        element: <AddPartnerImage />,
      },
      {
        path: "/partners/images-list/edit/:id",
        element: <AddPartnerImage />,
      },
    ],
  },
  {
    path: "/contacts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Contacts />,
      },
    ],
  },
  {
    path: "/carriers",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Carriers />,
      },
      {
        path: "add",
        element: <AddCarrier />,
      },
      {
        path: "edit/:id",
        element: <AddCarrier />,
      },
    ],
  },
  {
    path: "/claims",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Claims />,
      },
    ],
  },
  {
    path: "/opportunities",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Opportunities />,
      },
    ],
  },
  {
    path: "/seo",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Seo />,
      },
      {
        path: "add",
        element: <AddEditSeoMeta />,
      },
      {
        path: "edit/:id",
        element: <AddEditSeoMeta />,
      },
    ],
  },
  {
    path: "/privacy-policy",
    element: (
      <DocumentPage sectionTag="privacy-policy" title="Privacy Policy" />
    ),
  },
  {
    path: "/terms-of-use",
    element: (
      <DocumentPage sectionTag="defy-terms-of-use" title="Terms Of Use" />
    ),
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      // {
      //   path: "simple-table",
      //   element: <SimpleTable />,
      // },
      // {
      //   path: "advanced-table",
      //   element: <AdvancedTable />,
      // },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Chartjs />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
