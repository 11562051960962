import React, { useEffect } from "react";
import { getAll } from "../../Apis/privilegeApi";
import { useDispatch, useSelector } from "react-redux";
import { setPrivileges } from "../../redux/slices/privilegeSlice";

import {
  BookOpen,
  Grid,
  UserCheck,
  User,
  Sliders,
  Users,
  Layers,
  Server,
  Clipboard,
  Mail,
  Code,
  UserPlus,
  Truck,
  Key,
  DollarSign,
  Archive,
} from "react-feather";

export default function DashboardItems() {
  const dispatch = useDispatch();
  const privileges = useSelector((state) => state.privileges.privileges);
  const fetchPrivileges = () => {
    getAll().then((response) => {
      console.log("FROM DASHBOARD", response);
      dispatch(setPrivileges(response.data.payload));
    });
  };

  useEffect(() => {
    fetchPrivileges();
  }, []);

  const privilegeNames = privileges;
  console.log("From Dashboard Items", privilegeNames);

  const dashboardSection = [
    {
      href: "/",
      icon: Sliders,
      title: "Dashboard",
    },
  ];

  const blogSection = [
    {
      href: "/blogs",
      icon: BookOpen,
      title: "Manage Blogs",
      children: [
        {
          href: "/blogs/list",
          title: "Blogs",
        },
        {
          href: "/blogs/authors",
          title: "Authors",
        },
        {
          href: "/blogs/categories",
          title: "Categories",
        },
        {
          href: "/blogs/posts",
          title: "Posts",
        },
      ],
    },
  ];

  const CMSSection = [
    {
      href: "/cms/pages",
      icon: Layers,
      title: "Content Management System",
      // children: [
      //   {
      //     href: "/cms/pages",
      //     title: "Dynamic Pages",
      //   },
      // ],
    },
  ];
  const agentSection = [
    {
      href: "/agents",
      icon: UserCheck,
      title: "Manage Agents",
      children: [
        {
          href: "/agents/prospect-agents",
          title: "Prospect Agents",
        },
        {
          href: "/agents/list",
          title: "All Agents",
        },
      ],
    },
  ];

  const industrySection = [
    {
      href: "/industries",
      icon: Server,
      title: "Manage Industry",
      children: [
        {
          href: "/industry",
          title: "All Industries",
        },
        {
          href: "/faq",
          title: "FAQs",
        },
      ],
    },
  ];

  const professionalsSection = [
    {
      href: "/profession",
      icon: Archive,
      title: "Manage Profession",
    },
  ];

  const productSection = [
    {
      href: "/products",
      icon: Grid,
      title: "Manage Products",
      children: [
        {
          href: "/products/all",
          title: "All Products",
        },
        {
          href: "/products/faq",
          title: "FAQs",
        },
        {
          href: "/products/mapping",
          title: "Products Mapping",
        },
      ],
    },
  ];

  const roleSection = [
    {
      href: "/roles",
      icon: User,
      title: "Roles",
    },
  ];

  const adminSection = [
    {
      href: "/admins",
      icon: Users,
      title: "Admins",
    },
  ];

  const profileSection = [
    {
      href: "/profile",
      icon: User,
      title: "Profile",
    },
  ];

  const testimonialSection = [
    {
      href: "/testimonials",
      icon: Clipboard,
      title: "Testimonials",
    },
  ];

  // const newsLetterSection = [
  //   { href: "/news-letter", icon: Mail, title: "News Letter" },
  // ];

  // const faqSection = [
  //   {
  //     href: "/faq",
  //     icon: HelpCircle,
  //     title: "Faqs",
  //   },
  // ];

  const partnerSection = [
    {
      href: "/partners",
      icon: UserPlus,
      title: "Manage Partners",
      children: [
        {
          href: "/partners/prospect-partners",
          title: "Prospect-partners",
        },
        {
          href: "/partners/images-list",
          title: "Partner's Image",
        },
      ],
    },
  ];

  const leadsSection = [
    {
      href: "/fake-leads",
      icon: Mail,
      title: "Leads",
      children: [
        {
          href: "/contacts",
          title: "Contact Requests",
        },
        { href: "/news-letter", title: "News Letter" },
      ],
    },
  ];
  const privilegesSection = [
    {
      href: "/fake-privileges",
      icon: Key,
      title: "Privileges",
      children: [
        {
          href: "/admins",
          title: "Manage Admin",
        },
        { href: "/roles", title: "Manage role" },
      ],
    },
  ];

  // const contactSection = [
  //   {
  //     href: "/contacts",
  //     icon: UserPlus,
  //     title: "Contact Requests",
  //   },
  // ];

  const carrierSection = [
    {
      href: "/carriers",
      icon: Truck,
      title: "Carriers",
    },
  ];

  const opportunitySection = [
    {
      href: "/opportunities",
      icon: DollarSign,
      title: "Opportunities",
    },
  ];

  const seoSection = [
    {
      href: "/seo",
      icon: Code,
      title: "Manage Seo",
      children: [
        {
          href: "/seo",
          title: "Seo Meta",
        },
      ],
    },
  ];

  const claimsSection = [
    {
      href: "/claims",
      icon: Clipboard,
      title: "Claims",
    },
  ];

  const navItems = [
    {
      title: "",
      pages: dashboardSection,
      hidden: false,
    },
    {
      title: "",
      hidden: Boolean(!privilegeNames.includes("BLOG_READ")),
      pages: blogSection,
    },
    {
      title: "",
      hidden: Boolean(!privilegeNames.includes("PAGE_READ")),
      pages: CMSSection,
    },
    {
      title: "",
      pages: agentSection,
      hidden: Boolean(!privilegeNames.includes("AGENT_VIEW_ALL")),
    },
    {
      title: "",
      pages: industrySection,
      hidden: false,
    },
    {
      title: "",
      pages: professionalsSection,
      hidden: false,
    },
    {
      title: "",
      pages: productSection,
      hidden: false,
    },
    {
      title: "",
      pages: carrierSection,
      hidden: false,
    },
    {
      title: "",
      pages: testimonialSection,
      hidden: Boolean(!privilegeNames.includes("TESTIMONIAL_READ")),
    },
    // {
    //   title: "",
    //   pages: newsLetterSection,
    //   hidden: Boolean(!privilegeNames.includes("NEWSLETTER_READ")),
    // },
    // {
    //   title: "",
    //   pages: faqSection,
    //   hidden: false,
    // },
    {
      title: "",
      pages: partnerSection,
      hidden: false,
    },
    // {
    //   title: "",
    //   pages: contactSection,
    //   hidden: false,
    // },
    {
      title: "",
      pages: opportunitySection,
      hidden: false,
    },
    {
      title: "",
      pages: seoSection,
      hidden: false,
    },
    {
      title: "",
      pages: claimsSection,
      hidden: false,
    },
    {
      title: "",
      pages: leadsSection,
      hidden: false,
    },
    {
      title: "",
      pages: privilegesSection,
      hidden:
        Boolean(!privilegeNames.includes("ROLE_READ")) &&
        Boolean(!privilegeNames.includes("ADMIN_VIEW")),
    },
    // {
    //   title: "",
    //   pages: roleSection,
    //   hidden: Boolean(!privilegeNames.includes("ROLE_READ")),
    // },
    // {
    //   title: "",
    //   pages: adminSection,
    //   hidden: Boolean(!privilegeNames.includes("ADMIN_VIEW")),
    // },
    {
      title: "",
      pages: profileSection,
      hidden: false,
    },
  ];
  return navItems;
}
