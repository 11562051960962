import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Avatar,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";

const rows = [
  {
    name: "fake",
    calories: "gg",
    fat: 23,
    carbs: 2,
    protein: 20,
  },
];

export default function AuthorTable(props) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Avatar</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {row.image && row.image.fileUrl ? (
                  <Avatar alt="author image" src={row.image.fileUrl} />
                ) : (
                  "No Image"
                )}
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">
                <div>
                  <IconButton
                    onClick={() =>
                      navigate(`/blogs/${props.blogId}/authors/edit/${row.id}`)
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => props.deleteAuthor(row.id)}>
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
