import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Avatar, Paper } from "@mui/material";

import { signIn } from "../../Apis/authApi";
import { useMessage } from "../../hooks/useMessage";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { setUser } from "../../redux/slices/auth";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const message = useMessage();

  const isAuthenticated = useSelector((state) => state.auth.isAuthorize);

  const submit = (values) => {
    setLoading(true);
    const data = JSON.stringify(values);

    signIn(data)
      .then((response) => {
        message("Logged In", "success");
        dispatch(setUser(response.data));
        setLoading(false);
        return navigate("/");
      })
      .catch((err) => {
        message(err.response.data.message, "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <img
        src="/defy_logo.png"
        style={{ width: 150, height: 64, marginBottom: 32 }}
      />
      <Wrapper>
        <Helmet title="Sign In" />

        <SignInComponent loader={loading} submit={submit} />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
