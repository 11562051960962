import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllBlogs,
  getAllAuthors,
  deleteAuthor,
} from "../../../Apis/blogApi";
import { useMessage } from "../../../hooks/useMessage";

import AddButton from "../../components/AddButton";
import {
  CardContent,
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import params from "../../../utils/params";

import AuthorTable from "../../components/tables/authorTable";

import NotFound from "../../components/NotFound";
import ContentLoader from "../../components/contentLoader";
import SearchFilter from "../../components/SearchFilter";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Authors() {
  const [authors, setAuthors] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [filterBy, setFilterBy] = useState("email");
  const [searchBy, setSearchBy] = useState({
    email: "",
    name: "",
  });
  const navigate = useNavigate();
  const message = useMessage();
  const user = useSelector((state) => state.auth.user);
  const blogId = useSelector((state) => state.blog.blogId);

  const filters = [
    {
      value: "email",
      label: "Email",
    },
    {
      value: "name",
      label: "Name",
    },
  ];

  const getAuthors = () => {
    if (blogId && Object.keys(searchBy).length > 0) {
      setLoading(true);
      getAllAuthors(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          email: searchBy.email,
          name: searchBy.name,
        }),
        blogId
      )
        .then((response) => {
          setRowsPerPage(response.data.payload.size);
          setCount(response.data.payload.totalElements);
          setAuthors(response.data.payload.content);
          setLoading(false);
        })
        .catch((err) => {
          console.log("author fetch error", err);
          setLoading(false);
        });
    }
  };

  const getBlogs = () => {
    getAllBlogs()
      .then((response) => setBlogs(response.data.payload))
      .catch((err) => console.error("blog failed", err));
  };

  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };
  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteAuthor = () => {
    deleteAuthor(deleteId, user.id, blogId)
      .then((response) => {
        message("Author deleted", "success");
        setDeleteId(null);
        setShowDelete(false);
        getAuthors();
      })
      .catch((err) => message("Author delete failed", "error"));
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  //search
  const handleSearch = (e, type) => {
    const value = e.target.value;
    setSearchBy({ ...searchBy, [type]: value });
  };

  const handleFilter = (e) => {
    setFilterBy(e.target.value);
    setSearchBy({});
  };

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    getAuthors();
  }, [currentPage, blogId, searchBy]);
  return (
    <React.Fragment>
      <Helmet title="Blogs | Authors " />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this author?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={submitDeleteAuthor}
          >
            Delete
          </Button>
          <Button onClick={hideDeleteModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Authors
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Blogs</Typography>
            <Typography>Authors</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <AddButton
            data={blogs}
            btnText="Select Blog"
            variant="outlined"
            title="title"
          />
          <Button
            onClick={() => navigate(`/blogs/${blogId}/authors/add`)}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            disabled={!blogId}
          >
            <AddIcon />
            Add Author
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              {!blogId ? (
                <Typography sx={{ my: 2 }} align="center" variant="h4">
                  Please select blog
                </Typography>
              ) : (
                <>
                  <SearchFilter
                    filters={filters}
                    filterBy={filterBy}
                    handleFilter={handleFilter}
                    handleSearch={handleSearch}
                  />
                  {loading ? (
                    <ContentLoader />
                  ) : (
                    <>
                      {authors.length < 1 ? (
                        <NotFound />
                      ) : (
                        <>
                          <AuthorTable
                            deleteAuthor={showDeleteModal}
                            rows={authors}
                            blogId={blogId}
                          />
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            count={count}
                            page={currentPage}
                            rowsPerPage={rowsPerPage}
                            onPageChange={(_, page) => handleChangePage(page)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Authors;
