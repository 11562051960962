import React, { useRef, useState } from "react";
import { TextField, Box, Autocomplete } from "@mui/material";
import { debounce } from "../../utils/debounce";

export default function SearchFilter(props) {
  const autoInputRef = useRef(null);
  const [industryValue, setIndustryValue] = useState(null);

  const searchFieldWidth = 200;

  const renderField = () => {
    if (props.filterBy === "status" || props.filterBy === "type") {
      return (
        <TextField
          id="outlined-native"
          select
          size="small"
          label={`Select ${props.filterBy}`}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            debounce(() => props.handleSearch(e, props.filterBy), 500)
          }
          defaultValue={""}
          SelectProps={{
            native: true,
          }}
          sx={{ mr: 2, width: searchFieldWidth }}
        >
          <option key={20} value={""}>
            All
          </option>
          {props.typeOptions?.length > 0 ? (
            <>
              {props.typeOptions.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </>
          ) : (
            <>
              <option key={1} value={true}>
                {props.statusOptions?.length > 0
                  ? props.statusOptions[0]
                  : "Active"}
              </option>
              <option key={2} value={false}>
                {props.statusOptions?.length > 0
                  ? props.statusOptions[1]
                  : "Inactive"}
              </option>
            </>
          )}
        </TextField>
      );
    } else if (props.filterBy === "industries") {
      return (
        <Autocomplete
          size="small"
          loading={props.industryLoading}
          sx={{ width: searchFieldWidth }}
          value={industryValue}
          onChange={(event, value) => {
            setIndustryValue(value);
            props.handleSearch(value?.id ?? "", props.filterBy);
          }}
          options={props.industries}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              sx={{ width: searchFieldWidth + 150 }}
              {...params}
              variant="outlined"
              label="Select Industry"
              placeholder="Search Industry"
              inputRef={autoInputRef}
              onChange={(e) =>
                debounce(() => props.handleIndustrySearch(e.target.value), 500)
              }
            />
          )}
        />
      );
    } else {
      return (
        <TextField
          size="small"
          sx={{ width: searchFieldWidth }}
          label={`Search by ${props.filterBy
            ?.replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`)
            .trim()}`}
          variant="outlined"
          onChange={(e) =>
            debounce(() => props.handleSearch(e, props.filterBy), 500)
          }
        />
      );
    }
  };

  return (
    <Box display="flex" sx={{ mb: 3 }}>
      <TextField
        id="outlined-native"
        select
        size="small"
        label="Search By"
        value={props.filterBy}
        onChange={props.handleFilter}
        SelectProps={{
          native: true,
        }}
        sx={{ mr: 2 }}
      >
        {props.filters.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      {renderField()}
    </Box>
  );
}
