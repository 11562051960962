import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import { Modal, Button, Box, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ImageUpload(props) {
  const [open, setOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const uploadImage = async () => {
    // console.log("image uploaded", imageFile[0]);
    const data = new FormData();
    data.append("fileUploadType", props.imageType);
    data.append("file", imageFile[0]);

    await props.submitImage(data);

    handleClose();
  };

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            width: "50%",
            maxWidth: 300,
            border: "1px dashed #9E9E9E",
            "& .image": {
              display: "block",
              width: "100%",
              height: "auto",
            },
            "& .image-text": {
              position: "absolute",
              bottom: 0,
              background: "rgb(0,0,0,0.5)",
              color: "#f1f1f1",
              width: "100%",
              transition: ".5s ease",
              opacity: 0,
              color: "white",
              fontSize: 20,
              paddding: 20,
              textAlign: "center",
            },
            "&:hover": {
              cursor: "pointer",
              "& .image-text": {
                opacity: 1,
              },
            },
          }}
          onClick={handleOpen}
        >
          <img className="image" src={props.image} height={250} width={200} />
          <p className="image-text">
            {props.label ? props.label : "Upload Image"}
          </p>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            Upload Image
          </Typography>
          <ImageUploader
            withIcon={true}
            buttonText="Choose image"
            onChange={(e) => setImageFile(e)}
            imgExtension={[".jpg", ".png", ".jpeg", ".svg"]}
            maxFileSize={5242880}
            withPreview={true}
            singleImage={true}
            label="Max file size: 5mb, accepted: jpg ,jpeg ,png"
          />
          <Button variant="contained" onClick={uploadImage}>
            Upload
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Box>
      </Modal>
    </div>
  );
}
