import React from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";

import { orange, green, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -28px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const DoughnutChart = ({ theme, products, totalLeads }) => {
  const filterProducts = products
    ?.filter((product) => product.opportunityCount > 0)
    .sort((a, b) => b.opportunityCount - a.opportunityCount);

  //products are sorted to asc so O element is the max
  const maxLeadPercentage =
    (filterProducts[0]?.opportunityCount / totalLeads) * 100 || 0;

  const data = {
    // labels: ["Social", "Search Engines", "Direct", "Other"],
    labels: filterProducts.map((product) => product.name),
    datasets: [
      {
        // data: [260, 125, 54, 146],
        data: filterProducts.map((product) => product.opportunityCount),
        backgroundColor: [
          theme.palette.secondary.main,
          theme.palette.primary.dark,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings" size="large">
        //     <MoreVertical />
        //   </IconButton>
        // }
        title="Product Leads"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="caption">Top lead</Typography>
            <Typography variant="h4">{maxLeadPercentage}%</Typography>
            <Typography variant="caption">
              {filterProducts[0]?.name.slice(0, 16)}
            </Typography>
          </DoughnutInner>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="left">Lead</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterProducts?.map((product) => (
              <TableRow key={product.id}>
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>
                <TableCell align="left">{product.opportunityCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
